<template>
  <div class="bg-light">
    <DefaultHeader/>
    <b-container class="bg-light px-5">
      <b-row>
        <b-col>
          <h3 class="mt-3">Consulta Cartão Legal Especial</h3>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <p>Para fazer a consulta do protocolo, preencha o campo abaixo com o CPF do beneficiário</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm=4>
          <span>CPF</span>
          <b-input type="text" v-model="cpf" id="cpf" maxlength="14" @keyup="CPFValidation(cpf), cpfMask(cpf, 'cpf')"></b-input>
          <span>Protocolo:</span>
          <b-input type="text" v-model="protocol" id="protocol" maxlength="6"></b-input>
          <b-button class="button-color mt-3" :disabled="!validCpf" @click="getAnswer()">Buscar</b-button>
        </b-col>
      </b-row>
      <b-alert variant="warning" v-if="notFound" class="mt-3" show>Nenhum resultado foi encontrado. Aguarde o prazo estipulado pelo atendimento.</b-alert>
      <b-row class="rounded shadow border mt-3 p-3" v-show="answer != ''">
        <b-col sm=4>
          <img src="cartao_especial.png" class="rounded shadow w-100">
        </b-col>
        <b-col sm=8>
          <p>
            <span class="font-weight-bold">CID:</span> 
            {{answer.SL_CID}}
          </p>
          <p>{{answer.SD_RESPOSTA}}</p>
          <p>Em caso de dúvida, entre em contato conosco através dos números abaixo</p>
          <span class="mr-2 font-weight-bold">Whatsapp: (11) 97444-0954  |</span>
          <span class="font-weight-bold">Telefone: (11) 4122-8400</span>
          <p class="font-weight-bold">Horário de funcionamento: de segunda a sexta-feira, das 8h às 17h, e aos sábados, das 8h às 12h</p>
        </b-col>
      </b-row>
    </b-container>
    <CommonModals/>
  </div>
</template>

<script>
import CommonModals from '../components/modals.vue'
import DefaultHeader from '../components/defaultHeader.vue'

export default {
  components: {CommonModals,DefaultHeader},
  data() {
    return {
      cpf: '',
      protocol: '',
      answer: [],
      notFound: false,
      validCpf: false
    }
  },
  methods: {
    CPFValidation(strCPF) {
      if(strCPF.length == 14) {
        strCPF = strCPF.replace('.','')
        strCPF = strCPF.replace('.','')
        strCPF = strCPF.replace('-','')
        var Soma;
        var Resto;
        var i;
        Soma = 0;
        if (strCPF == "00000000000") {
          document.getElementById('cpf').classList.add('is-invalid');
          this.validCpf = false
          return
        }

        for (i=1; i<=9; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (11 - i);
        Resto = (Soma * 10) % 11;

        if ((Resto == 10) || (Resto == 11)) Resto = 0;
        if (Resto != parseInt(strCPF.substring(9, 10)) ){
          document.getElementById('cpf').classList.add('is-invalid');
          this.validCpf = false
          return
        }

        Soma = 0;
        for (i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (12 - i);
        Resto = (Soma * 10) % 11;

        if ((Resto == 10) || (Resto == 11))  Resto = 0;
        if (Resto != parseInt(strCPF.substring(10, 11) ) ) {
          document.getElementById('cpf').classList.add('is-invalid');
          this.validCpf = false
          return
        }
        
        document.getElementById('cpf').classList.remove('is-invalid');
        document.getElementById('cpf').classList.add('is-valid');
        this.validCpf = true
        return
      }
    },
    cpfMask(value, id) {
      if(value.length == 3) {
        document.getElementById(id).value += '.';
      }
      if(value.length == 7) {
        document.getElementById(id).value += '.';
      }
      if(value.length == 11) {
        document.getElementById(id).value += '-';
      }
    },
    async getAnswer() {
      this.answer = []
      this.notFound = false
      var formattedCpf = this.cpf.replace('.','').replace('.','').replace('-','')
      await this.callApi('post', 'specialConsulting', {cpf: formattedCpf, protocol: this.protocol}).then(response => {
        const result = response.data
        if(result.length != []){
          this.answer = result[0]
          return
        }
        this.notFound = true
      }).catch((err) => {
        this.$bvModal.show('error-modal')
        console.log(err)
      });
    }
  }
}
</script>

<style>
.container {
  height: 100vh;
  /* overflow: hidden; */
}
.button-color {
  background-color: #002e8a;
  border: none;
}
</style>